.agreement-goal-row {
  align-items: center;

  .remove {
    color: red;
    cursor: pointer;

    span {
      pointer-events: none;
    }
  }

  .method {
    color: rgb(62, 109, 202);
    font-weight: 700;
    cursor: pointer;

    span {
      pointer-events: none;
    }
  }

  &.first {
    .remove,
    .method {
      padding-top: 1em;
    }
  }
}