.page.agent {

  .sub-top-line {
    padding-top: 30px;
    padding-inline: 30px;
    display: flex;
    column-gap: 30px;

    .load-file {
      width: 162px;
      height: 36px;
      border: 1px solid #1e9a3f;
      border-radius: 4px;
      color: #1e9a3f;
      background: transparent;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;
    }
  
    .search {
      padding: 0.7em;
      background-color: rgba($color: #000000, $alpha: 0.02);
      border-color: rgba($color: #000000, $alpha: 0.13);
      border-radius: 5px;
      min-width: 170px;
    }

    .Dropdown-control {
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .content {
    padding: 2rem 30px 1rem 30px;
  }
}