.table {
  &.sets-table {
    margin-top: 2rem;
    min-width: initial;
    width: min(400px, 100%);
    max-height: 68vh;
    overflow: auto;
    padding-inline-end: 10px;

    @media screen and (max-width: 480px) {
      width: min(250px, 100%);
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #b7b7b7;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #8d8c8c;
    }

    .row {
      display: flex;
      margin-bottom: 5px;
      column-gap: 5px;

      &.head {
        margin-bottom: 5px;

        .cell {
          color: #3A3BB6;
          font-weight: 400;
        }
      }

      &:not(.head) {
        cursor: pointer;

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.06);
        }

        .cell {
          background-color: rgba($color: #000000, $alpha: 0.05);
        }
      }

      .cell {
        flex: 1;
        padding: 13px;
        font-size: 0.9em;
        pointer-events: none;
      }
    }
  }
}