.agent-number-row {

  flex-wrap: nowrap !important;

  .remove {
    max-width: 30px;
    margin-inline-start: 10px;
    color: red;
    padding-top: 1em;

    span {
      cursor: pointer;
    }
  }
}