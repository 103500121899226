.page.agents {
	.sub-top-line {
		padding-top: 30px;
		padding-inline: 30px;
		display: flex;
		flex-wrap: wrap;
		column-gap: 30px;
		row-gap: 30px;
		// justify-content: space-between;

		.search {
			padding: 0.7em;
			background-color: rgba($color: #000000, $alpha: 0.02);
			border-color: rgba($color: #000000, $alpha: 0.13);
			border-radius: 5px;
			min-width: 170px;
			margin-inline-end: auto;
		}

		.add-agent {
			height: 42px;
			padding: 8px 20px;
			font-size: 20px;
			background-color: #ebb546;
			border-radius: 4px;
			box-shadow: 0px 1px 3px #00000033;
			border: none;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			column-gap: 5px;
		}
		.load-file,
		.export-file {
			width: 162px;
			height: 36px;
			border: 1px solid #1e9a3f;
			border-radius: 4px;
			color: #1e9a3f;
			background: transparent;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			cursor: pointer;

			&.loading {
				opacity: 0.7;
				pointer-events: none;
			}

			img {
				width: 20px;
			}
		}
	}

	.content {
		padding: 2rem 30px 5rem 30px;
	}
}
