.table {
 &.agents-table {
  margin-top: 2rem;
  width: 100%;

  .row {
    display: flex;
    margin-bottom: 5px;
    column-gap: 5px;


    &.head {
      margin-bottom: 5px;

      .cell {
        color: #3A3BB6;
        font-weight: 400;
      }
    }

    &:not(.head) {
      cursor: pointer;
      
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.06);
      }

      .cell {
        background-color: rgba($color: #000000, $alpha: 0.05);
      }
    }

    .cell {
      flex: 1;
      padding: 13px;
      font-size: 0.9em;
      
      &:not(.flow-cell) {
        pointer-events: none;
      }

      &.flow-cell {
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.active {
        background-color: rgb(63, 175, 100);
        color: white;
      }
      
      &.not-active {
        background-color: rgb(234, 57, 57);
        color: white;
      }


      .agent-flow {
        height: 32px;
        padding: 8px 20px;
        font-size: 20px;
        background-color: #ebb546;
        border-radius: 4px;
        box-shadow: 0px 1px 3px #00000033;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        column-gap: 5px;

        * {
          pointer-events: none;
        }
      }
    }
  }
 }
}