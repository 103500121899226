.agent-row {
	.gain {
		&.green {
			color: green;
		}

		&.red {
			color: red;
		}
	}
}