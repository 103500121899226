.modal-flow {
  background-color: rgb(248, 248, 248);
  border-radius: 5px;
  width: 700px;
  max-width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    padding: 20px 40px 40px;
    max-height: 80vh;
    width: 95%;
    overflow: auto;
    padding: 20px 40px 30px;

    .back {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      column-gap: 5px;
      cursor: pointer;
      padding: 0;
  
      &:hover {
        opacity: 0.8;
      }
  
      img {
        width: 1em;
        height: 1em;
      }
    }
  
    h2 {
      text-align: center;
    }
  
    .export-file {
      width: 162px;
      height: 36px;
      border: 1px solid #1e9a3f;
      border-radius: 4px;
      color: #1e9a3f;
      background: transparent;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;
    }
  
    .container-content {
      display: flex;
      flex-direction: column;
  
      .row-add {
        margin-top: 8px;
        padding-inline-start: 58px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
  
        .add {
          border: none;
          padding: 8px 20px;
          background-color: rgba(0, 0, 0, 0.05);
          cursor: pointer;
  
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
  
          img {
            pointer-events: none;
          }
        }
      }
  
      .row-bottom {
        margin-top: 25px;
        padding-inline-start: 58px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 30px;
        justify-content: space-between;
        align-items: flex-end;
  
        .sum {
          border: solid 2px rgb(28, 157, 28);
          max-width: fit-content;
          padding: 5px 15px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          row-gap: 8px;
  
          span {
            text-align: center;
          }
  
          p {
            margin: 0;
            text-align: center;
            font-weight: 700;
            color: rgb(28, 157, 28);
          }
        }
  
        .submit-button {
          height: 42px;
          padding: 8px 20px;
          font-size: 20px;
          background-color: #ebb546;
          border-radius: 4px;
          box-shadow: 0px 1px 3px #00000033;
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          column-gap: 10px;
        }
      }
    }
  }
}