.page.reset {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-image: url('../../assets/main-login.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    background-color: white;
    border-radius: 15px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 350px;
    max-width: 100vw;
    min-height: 470px;
    box-shadow: 0px 0px 15px 1px #a5a5a5;
    margin-inline: 5%;

    .logo {
      text-align: center;
    }

    h3 {
      margin: 10px 0;
    }

    .field {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 5px;
      width: 100%;
      margin-top: 15px;
      width: 250px;

      label {
        font-size: 0.8em;
      }

      input {
        padding: 0.7em;
        background-color: rgba($color: #000000, $alpha: 0.02);
        border-color: rgba($color: #000000, $alpha: 0.13);
        border-radius: 5px;
        min-width: 170px;
        width: 90%;
      }
    }

    .submit {
      background-color: #ebb546;
      width: 250px;
      font-size: 20px;
      border: none;
      border-radius: 5px;
      box-shadow: 0px 0px 10px -3px #919191;
      padding: 0.4em 0.5em;
      margin-top: 35px;
      transition: background-color 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #d59d2c;
      }
    }

    
    .error-message {
      margin-inline: 10%;
      text-align: center;
    }
  }
}