.table {
  &.companies-table {
    margin-top: 2rem;
    width: 100%;
    max-height: 68vh;
    overflow: auto;
    padding-inline-end: 10px;

    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #b7b7b7;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #8d8c8c;
    }

    .row {
      display: flex;
      margin-bottom: 5px;
      column-gap: 5px;

      &.head {
        margin-bottom: 5px;

        .cell {
          color: #3A3BB6;
          font-weight: 400;
        }
      }

      &.sum {
        cursor: pointer;

        .cell {
          background-color: rgba($color: #000000, $alpha: 0.09);
        }

        &:hover {
          .cell {
            background-color: rgba($color: #000000, $alpha: 0.11);
          }
        }

        .cell {
          font-weight: 700;
          color: rgba($color: #000000, $alpha: 0.65);
        }
      }

      &:not(.head):not(.sum) {
        .cell {
          background-color: rgba($color: #000000, $alpha: 0.05);
        }
      }

      &.hide {
        max-height: 0px;
        overflow: hidden;
        margin: 0;
      }

      .cell {
        flex: 1;
        padding: 13px;
        font-size: 0.9em;
        pointer-events: none;
      }
    }
  }
}