.modal-new-agent {
  background-color: rgb(248, 248, 248);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  margin-inline: 5%;

  .inner {
    padding: 20px 40px 40px;
    max-height: 80vh;
    width: 95%;
    overflow: auto;

    @media screen and (max-width: 400px) {
      padding: 20px 15px 30px;
    }

    .back {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      column-gap: 5px;
      cursor: pointer;
      padding: 0;
  
      &:hover {
        opacity: 0.8;
      }
  
      img {
        width: 1em;
        height: 1em;
      }
    }
  
    .container-halves {
      display: flex;
      column-gap: 60px;

      @media screen and (max-width: 1000px) {
        flex-direction: column;
        row-gap: 50px;
      }
  
      .half1,
      .half2 {
        display: flex;
        flex-direction: column;
  
        .row {
          display: flex;
          flex-wrap: wrap;
          column-gap: 10px;
          row-gap: 15px;
          align-items: center;
  
          .cell {
            display: flex;
            flex-direction: column;
            row-gap: 6px;
            flex: 1;
  
            label {
              font-size: 0.8em;
            }
  
            input,
            textarea {
              padding: 0.7em;
              background-color: rgba($color: #000000, $alpha: 0.02);
              border-color: rgba($color: #000000, $alpha: 0.13);
              border-radius: 5px;
  
              &.error {
                border-color: red;
              }
  
              &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: rgba($color: #000000, $alpha: 0.45);
                opacity: 1;
                /* Firefox */
              }
  
              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: rgba($color: #000000, $alpha: 0.45);
              }
  
              &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: rgba($color: #000000, $alpha: 0.5);
                color: rgba($color: #000000, $alpha: 0.45);
              }
            }
          }
        }

        .row-actions {
          margin-top: 20px;
          display: flex;
          column-gap: 20px;
          
          &:not(.mobile) {
            display: flex;

            @media screen and (max-width: 1000px) {
              display: none;
            }
          }

          &.mobile {
            display: none;

            @media screen and (max-width: 1000px) {
              display: flex;
            }
          }
  
          .submit-button {
            height: 42px;
            padding: 8px 20px;
            font-size: 20px;
            background-color: #ebb546;
            border-radius: 4px;
            box-shadow: 0px 1px 3px #00000033;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            column-gap: 10px;
          }
  
          .delete-button {
            height: 42px;
            padding: 8px 20px;
            font-size: 20px;
            background-color: transparent;
            border-radius: 4px;
            box-shadow: 0px 1px 3px #00000033;
            border: solid 1px red;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            column-gap: 10px;
            color: red;
            transition: all 0.3s ease-in-out;
  
            &:hover {
              border-color: transparent;
              background-color: red;
              color: white;
            }
          }
        }
      }
  
  
      .half1 {
        flex: 1;
        row-gap: 25px;
  
        .set {
          max-width: 66%;
        }
      }
  
  
      .half2 {
        flex: 1;
        min-width: 20vw;
        row-gap: 15px;
  
        img.add {
          transition: transform 0.2s ease-in-out;
          cursor: pointer;
  
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}