.app {
	display: flex;

	> nav {
	}

	.page {
		flex: 1;
		max-width: 100vw;
		overflow-x: auto;

		@media screen and (max-width: 1500px) {
			overflow-x: auto;

			> .content {
				overflow-x: auto;
			}
		}

		> .content {
			max-width: 100vw;
		}
	}

	.table {
		min-width: 1300px;
	}
}

.modal-bg {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	backdrop-filter: blur(5px);
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.1);
	z-index: 2;
}
