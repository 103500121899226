.top-line {
  background-color: #d28440;
  padding: 4rem;
  position: relative;
  display: flex;
  align-items: center;

  h1 {
    margin: 0;
    text-align: center;
    color: white;
    width: 100%;
  }

  .hamburger {
    position: absolute;
    right:20px;
    transition: right 0.5s ease-in-out;
    display: none;

    @media screen and (max-width: 1500px) {
      display: block;
    }

    &.open {
      right: 308px;

      @media screen and (max-width: 350px) {
        right: 258px;
      }
    }
  }
}