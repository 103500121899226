.set-agreement-row {
	display: flex;

	border-radius: 5px;
	overflow: hidden;

	.cell {
		padding: 0.5em 0.7em;
		flex: 1;
		text-align: center;

		&:not(:last-of-type):not(.cell-remove) {
			border-inline-end: solid 1px #e0dfdf;
		}

		&.cell-remove {
			max-width: fit-content;
			cursor: pointer;

			&:hover {
				img {
					transform: scale(1.1);
				}
			}

			img {
				pointer-events: none;
				transition: transform 0.4s ease-in-out;
			}
		}
	}
}