.row.sum {
	.cell {

		display: flex;
		flex-direction: column;
		row-gap: 3px;

		.gain {
			font-size: 0.9em;
			&.green {
				color: green;
			}

			&.red {
				color: red;
			}
		}
	}
}