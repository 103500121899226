.page.settings {

  .instructions {
    padding: 2rem 30px 1rem 30px;
    line-height: 1.4em;
    font-size: 1.1em;
    
    @media screen and (max-width: 1500px) {
      padding: 2rem 5% 1rem 5%;
    }
  }

  .wrapper-input {
    display: flex;
    column-gap: 20px;
    width: 250px;
    margin-inline: 30px;

    input {
      width: 100%;
    }

    button {
      height: 42px;
      padding: 4px 15px;
      font-size: 17px;
      background-color: #e48a2f;
      color: white;
      border-radius: 4px;
      box-shadow: 0px 1px 3px #00000033;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      column-gap: 5px;
    }
  }

  input {
    padding: 0.7em;
    background-color: rgba($color: #000000, $alpha: 0.02);
    border-color: rgba($color: #000000, $alpha: 0.13);
    border-radius: 5px;
    min-width: 170px;
  }

  .content {
    padding: 2rem 30px 1rem 30px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 150px;

    @media screen and (max-width: 1500px) {
      padding: 2rem 5% 1rem 5%;
    }
    
    .wrapper-add {
      display: flex;
      column-gap: 15px;

      button {
        height: 42px;
        padding: 4px 15px;
        font-size: 17px;
        background-color: #e48a2f;
        color: white;
        border-radius: 4px;
        box-shadow: 0px 1px 3px #00000033;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        column-gap: 5px;
      }
    }

    ul {
      padding: 0;
      list-style: none;
      min-width: 200px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      max-width: fit-content;
      margin-top: 35px;

      li {
        background-color: #f5e1cc;
        padding: 0.4em 0.6em;
        border-radius: 5px;
        display: flex;
        column-gap: 15px;

        img {
          cursor: pointer;
        }
      }
    }
  }

  .wrapper-change-pas {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    input {
      width: 130px;
      margin-inline-start: 30px;
    }

    button {
      width: 130px;
      height: 42px;
      padding: 4px 15px;
      font-size: 17px;
      background-color: #e48a2f;
      margin-inline-start: 30px;
      margin-top: 10px;
      color: white;
      border-radius: 4px;
      box-shadow: 0px 1px 3px #00000033;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      column-gap: 5px;
    }

    .error-message {
      margin-inline-start: 30px;
      color: darkred;
    }
  }
}