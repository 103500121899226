.page.sets {

  .sub-top-line {
    padding-top: 30px;
    padding-inline: 30px;
    display: flex;
    column-gap: 30px;

    .add-set {
      height: 42px;
      padding: 8px 20px;
      font-size: 20px;
      background-color: #ebb546;
      border-radius: 4px;
      box-shadow: 0px 1px 3px #00000033;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      column-gap: 10px;

      img {
        max-height: 100%;
      }
    }
  }

  .content {
    padding: 2rem 30px 1rem 30px;

    @media screen and (max-width: 400px) {
      padding: 2rem 20px 1rem 20px;
    }
  }
}