.sidebar {
  padding: 2rem;
  padding-left: 4rem;
  padding-inline-start: 0;
  background-color: white;

  @media screen and (max-width: 1500px) {
    position: absolute;
    top: 0;
    height: 100vh;
    right: -400px;
    background-color: rgb(253, 253, 253);
    transition: right 0.5s ease-in-out;
    width: 250px;
    z-index: 1;

    &.open {
      right: 0;
    }
  }

  @media screen and (max-width: 350px) {
    width: 200px;
  }

  .logo {
    width: 145px;
    height: 145px;
    margin-inline-start: 5rem;
    border-bottom: solid 1px rgba($color: #000000, $alpha: 0.15);
  }

  .side-menu {
    list-style: none;
    padding: 3rem 0 0 0;
    
    li {

      &:not(:first-of-type) {
        margin-top: 0.5rem;
      }
      
      a,
      p {
        display: flex;
        column-gap: 15px;
        align-items: center;
        transition: background-color 0.4s ease-in-out;
        padding: 0.8rem 5rem 0.8rem 3rem;
        border-radius: 30px 0 0 30px;

        &:hover,
        &.active {
          background-color: rgba($color: #000000, $alpha: 0.07);
        }

        img {
          max-height: 25px;
          width: 25px;
        }
      }

      &:last-of-type {
        margin-top: 40px;
        p {
          color: rgb(206, 14, 14);
          cursor: pointer;
        }
      }
    }
  }
}