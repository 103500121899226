.modal-new-agreement {
  background-color: rgb(248, 248, 248);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 95%;
  margin-inline: 5%;

  .inner {
    padding: 20px 40px 40px;
    max-height: 80vh;
    width: 95%;
    overflow: auto;

    @media screen and (max-width: 400px) {
      padding: 20px 15px 30px;
    }

    .back {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      column-gap: 5px;
      cursor: pointer;
      padding: 0;

      &:hover {
        opacity: 0.8;
      }

      img {
        width: 1em;
        height: 1em;
      }
    }

    h2 {
      font-size: 22px;
      font-weight: 500;
    }

    .container-halves {
      display: flex;
      column-gap: 60px;

      @media screen and (max-width: 1200px) {
        flex-direction: column;
        row-gap: 50px;
      }

      .half1,
      .half2 {
        display: flex;
        flex-direction: column;

        .row {
          display: flex;
          column-gap: 15px;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 20px;

          @media screen and (max-width: 790px) {
            flex-wrap: wrap;
            row-gap: 20px;
          }

          .cell {
            display: flex;
            flex-direction: column;
            row-gap: 6px;
            flex: 1;

            label {
              font-size: 0.8em;
            }

            input,
            textarea {
              padding: 0.7em;
              background-color: rgba($color: #000000, $alpha: 0.02);
              border-color: rgba($color: #000000, $alpha: 0.13);
              border-radius: 5px;
              width: 140px;

              &.error {
                border-color: red;
              }

              &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: rgba($color: #000000, $alpha: 0.45);
                opacity: 1;
                /* Firefox */
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: rgba($color: #000000, $alpha: 0.45);
              }

              &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: rgba($color: #000000, $alpha: 0.5);
                color: rgba($color: #000000, $alpha: 0.45);
              }
            }
          }
        }

        .row-actions {
          margin-top: 30px;
          display: flex;
          column-gap: 20px;

          &:not(.mobile) {
            display: flex;

            @media screen and (max-width: 1200px) {
              display: none;
            }
          }

          &.mobile {
            display: none;

            @media screen and (max-width: 1200px) {
              display: flex;
            }
          }

          .submit-button {
            height: 42px;
            padding: 8px 20px;
            font-size: 20px;
            background-color: #ebb546;
            border-radius: 4px;
            box-shadow: 0px 1px 3px #00000033;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            column-gap: 10px;
          }

          .delete-button {
            height: 42px;
            padding: 8px 20px;
            font-size: 20px;
            background-color: transparent;
            border-radius: 4px;
            box-shadow: 0px 1px 3px #00000033;
            border: solid 1px red;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            column-gap: 10px;
            color: red;
            transition: all 0.3s ease-in-out;

            &:hover {
              border-color: transparent;
              background-color: red;
              color: white;
            }
          }
        }
      }

      .half1 {
        flex: 1;
        row-gap: 5px;

        .MyDrop {
          width: 130px;

          @media screen and (max-width: 790px) {
            width: 130px;
          }
        }

        .row-cancellations {
          display: flex;
          column-gap: 25px;

          .col {
            span:first-of-type {
              font-size: 0.8em;
              margin: 0;
            }

            .container-cancellations-years,
            .container-cancellations-percentages {
              margin-top: 10px;
              display: flex;
              flex-direction: column;
              row-gap: 8px;

              p {
                margin: 0;
                height: 1.95em;
                display: flex;
                align-items: center;
                padding-inline-start: 0.5em;
                font-size: 0.9em;
              }

              input {
                padding: 0.4em;
                background-color: rgba($color: #000000, $alpha: 0.02);
                border-color: rgba($color: #000000, $alpha: 0.13);
                border-radius: 5px;
                width: 60px;
              }
            }
          }
        }

        .row-plus-minus {
          margin-top: 10px;
          display: flex;
          column-gap: 15px;

          .add-cancellation-year,
          .subtract-cancellation-year {
            width: 20px;
            transition: transform 0.4s ease-in-out;
            cursor: pointer;

            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }

      .half2 {
        flex: 1;
        min-width: 20vw;
        row-gap: 0px;

        .agreement-goal-row {
          max-width: min(350px, 90%);
        }

        .MyDrop:first-child {
          width: 12em;
          max-width: 100%;
          margin-bottom: 15px;

          .current {
            background-color: transparent;
            border-color: transparent;

            padding-bottom: 0.3em;
          }
        }

        .add {
          width: 30px;
          cursor: pointer;
          transition: transform 0.4s ease-in-out;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}