.modal-set {
	background-color: rgb(248, 248, 248);
	border-radius: 5px;
	width: 1200px;
	max-width: 90%;
	margin-inline: 5%;
	display: flex;
	justify-content: center;
	align-items: center;

	.inner {
		padding: 20px 40px 40px;
		max-height: 80vh;
		width: 95%;
		overflow: auto;

		.buttons {
			display: flex;
			width: 100%;
			justify-content: space-between;
			.back {
				background-color: transparent;
				border: none;
				display: flex;
				align-items: center;
				column-gap: 5px;
				cursor: pointer;
				padding: 0;

				&:hover {
					opacity: 0.8;
				}

				img {
					width: 1em;
					height: 1em;
				}
			}
			.deleteSet {
				background-color: transparent;
				border: none;
				display: flex;
				align-items: center;
				column-gap: 5px;
				cursor: pointer;
				padding: 0;

				&:hover {
					opacity: 0.8;
				}

				img {
					width: 1em;
					height: 1em;
				}
			}
		}

		h2 {
			text-align: center;
		}

		.set-name {
			padding: 0.7em;
			background-color: rgba($color: #000000, $alpha: 0.02);
			border-color: rgba($color: #000000, $alpha: 0.13);
			border-radius: 5px;
			min-width: min(250px, 90%);
		}

		.container-agreements {
			display: flex;
			flex-direction: column;
			row-gap: 10px;

			.row {
				&:not(:first-of-type) {
					border-top: solid 1px #e0dfdf;
					padding-top: 10px;
				}
			}
		}

		.line {
			width: 100%;
			background-color: #c5c2c2;
			height: 1px;
			margin-inline: auto;
			margin-block: 40px;
		}

		.search-agreement {
			padding: 0.7em;
			background-color: rgba($color: #000000, $alpha: 0.02);
			border-color: rgba($color: #000000, $alpha: 0.13);
			border-radius: 5px;
			min-width: min(250px, 90%);
		}

		.title {
			margin-top: 30px;
			text-align: center;
			font-weight: 600;
			font-size: 1.1em;
		}

		.container-results {
			margin-top: 15px;
			margin-bottom: 50px;

			.row {
				.cell {
					pointer-events: none;
				}
			}
		}

		.container-agreements,
		.container-results {
			max-width: 95%;
			overflow-x: auto;
			margin-inline: auto;
			padding-bottom: 10px;

			.row {
				min-width: 700px;
			}
		}

		.submit-button {
			height: 42px;
			padding: 8px 20px;
			font-size: 20px;
			background-color: #ebb546;
			border-radius: 4px;
			box-shadow: 0px 1px 3px #00000033;
			border: none;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			column-gap: 10px;
			margin-inline: auto;
			margin-top: 40px;
		}

		// .error {
		// 	text-align: center;
		// 	margin-top: 40px;
		// 	color: red;
		// }
	}
}

.error {
	border-color: red !important;
}
