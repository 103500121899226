.table {
  &.agent-table {
    margin-top: 2rem;
    width: 100%;
    max-height: 61vh;
    overflow: auto;
    padding-inline-end: 10px;

    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #b7b7b7;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #8d8c8c;
    }

    .row {
      display: flex;
      margin-bottom: 5px;
      column-gap: 5px;

      &.head {
        margin-bottom: 5px;
        position: sticky;
        top: 0;
        background-color: #fff;

        .cell {
          color: #3a3bb6;
          font-weight: 400;
        }
      }

      &.sum {
        cursor: pointer;

        .cell {
          background-color: rgba($color: #000000, $alpha: 0.09);
        }

        &:not(.shown):hover {
          .cell {
            background-color: rgba($color: #000000, $alpha: 0.11);
          }
        }

        &.shown {
          .cell {
            background-color: rgba($color: #000000, $alpha: 0.17);
          }
        }

        .cell {
          font-weight: 700;
          color: rgba($color: #000000, $alpha: 0.65);
        }
      }

      &:not(.head):not(.sum) {
        .cell {
          background-color: rgba($color: #000000, $alpha: 0.05);
        }
      }

      &.hide {
        max-height: 0px;
        overflow: hidden;
        margin: 0;
      }

      .cell {
        flex: 1;
        padding: 13px;
        font-size: 0.9em;
        pointer-events: none;
      }
    }
  }
}

.loading-spinner-container {
  width: 100%;
  height: 100%;
  background-color: #3f3f3f1e;
  position: fixed;
  inset: 0;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px; /* Adjust height as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-spinner:before {
  content: '';
  border: 2px solid #ccc;
  border-top: 2px solid #007bff; /* Change color to match your theme */
  border-radius: 50%;
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  animation: spin 1s linear infinite;
  margin-inline-end: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
