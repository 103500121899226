.MyDrop {
	font-size: 1.4rem;
	cursor: pointer;
	position: relative;

	&.colorized {
		.color-0 {
			background-color: rgb(234, 57, 57) !important;
			color: white;

			&.option {
				&:hover {
					background-color: rgb(236, 43, 43) !important;
				}
			}
		}
		.color-1 {
			background-color: rgb(63, 175, 100) !important;
			color: white;

			&.option {
				&:hover {
					background-color: rgb(59, 201, 107) !important;
				}
			}
		}
	}

	.current {
		padding: 0.7em;
		background-color: rgba($color: #000000, $alpha: 0.02);
		border-radius: 5px;
		border: solid 2px rgba($color: #000000, $alpha: 0.13);
		display: flex;
		justify-content: space-between;
		align-items: center;

		span {
			pointer-events: none;
		}

		img {
			width: 18px;
			pointer-events: none;
		}
	}

	.dropdown {
		position: absolute;
		left: 0;
		right: 0;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		border: solid 1px rgba($color: #000000, $alpha: 0.13);
		border-top-width: 0px;
		border-radius: 0 0 5px 5px;
		z-index: 1;
		max-height: 500px;
		overflow-y: auto;

		.option {
			padding: 0.6em 0.7em;
			background-color: rgba($color: #edecec, $alpha: 1);
			transition: background-color 0.4s ease-in-out;

			&:not(:first-of-type) {
				border-top: solid 1px rgba($color: #000000, $alpha: 0.13);
			}

			&:hover {
				background-color: rgba($color: #e4e4e4, $alpha: 1);
			}
		}
	}

	&:not(.open) {
		.dropdown {
			max-height: 0px;
			overflow: hidden;
			border-color: transparent;
		}
	}
}
