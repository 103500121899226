.search-agreement-row {
	display: flex;
	background-color: #eeeeee;
	border-radius: 5px;
	overflow: hidden;
	cursor: pointer;

	&:hover {
		background-color: #e7e7e7;
	}

	.cell {
		padding: 0.5em 0.7em;
		flex: 1;
		text-align: center;

		&:not(:last-of-type) {
			border-inline-end: solid 1px #e0dfdf;
		}
	}
}