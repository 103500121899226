.table {
	min-width: unset !important;
	&.flow-table {
		margin-top: 2rem;
		width: 100%;
		max-height: 40vh;
		overflow: auto;

		max-width: 95%;
		overflow-x: auto;
		margin-inline: auto;
		padding-bottom: 10px;

		.row {
			min-width: 500px;
		}

		.row {
			display: flex;
			margin-bottom: 5px;
			column-gap: 5px;

			&.head {
				margin-bottom: 5px;

				.cell {
					color: #3a3bb6;
					font-weight: 400;
					text-align: center;

					&:first-of-type {
						max-width: 30px;
					}
				}
			}

			&:not(.head) {
				.cell {
					&:not(.cell-remove) {
						background-color: rgba($color: #000000, $alpha: 0.05);
					}

					&.cell-remove {
						max-width: 30px;
						pointer-events: painted !important;

						img {
							cursor: pointer;

							&:hover {
								transform: scale(1.2);
							}
						}
					}
				}
			}

			.cell {
				flex: 1;
				padding: 13px;
				font-size: 0.9em;
			}
		}

		.row-form {
			display: flex;

			.cell {
				flex: 1;
				padding: 13px 0;
				font-size: 0.9em;

				&.cell-remove {
					max-width: 30px;
					padding: 13px;

					img {
						cursor: pointer;

						&:hover {
							transform: scale(1.2);
						}
					}
				}

				input {
					width: 80%;
					border-color: rgba($color: #000000, $alpha: 0.2);
					border-radius: 5px;
					padding: 5px;
				}

				&:last-of-type {
					input {
						width: 90%;
					}
				}
			}
		}
	}
}
