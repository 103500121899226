html {
	font-size: 10px;

	body {
		direction: rtl;
		font-family: 'Assistant', sans-serif !important;
		color: #0a0a0a;
		font-size: 1.8rem;
		margin: 0;

		#root,
		.app {
			min-height: 100vh;
			min-width: 100vw;
		}

		input,
		textarea {
			font-family: 'Assistant', sans-serif !important;
		}

		a {
			color: inherit;
			text-decoration: none;
		}
	}
}
