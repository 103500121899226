.page.agreements {

  .sub-top-line {
    padding-top: 30px;
    padding-inline: 30px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
    align-items: flex-end;
    justify-content: space-between;

    &.second {
      padding-top: 13px;
    }

    .wrapper-filter {
      display: flex;
      flex-direction: column;
      row-gap: 6px;

      .MyDrop {
        width: 11em;
      }

      label {
        font-size: 0.9em;
      }

      .Dropdown-root {
        .Dropdown-control {
          border-radius: 5px;
          cursor: pointer;
          background-color: rgba(237, 236, 236, 0.6);
          .Dropdown-placeholder {
            padding-inline: 8px;
          }
        }
      }
    }
    
    .add-agreement {
      height: 42px;
      padding: 8px 20px;
      font-size: 20px;
      background-color: #ebb546;
      border-radius: 4px;
      box-shadow: 0px 1px 3px #00000033;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      column-gap: 5px;
    }
  }

  .content {
    padding: 2rem 30px 5rem 30px;
  }
}