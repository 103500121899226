.page.agent {

  .sub-top-line {
    padding-top: 30px;
    padding-inline: 30px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;

    &:nth-of-type(2) {
      justify-content: space-between;
    }

    .load-file,
    .export-file {
      width: 162px;
      height: 36px;
      border: 1px solid #1e9a3f;
      border-radius: 4px;
      color: #1e9a3f;
      background: transparent;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;

      &.loading {
        opacity: 0.7;
        pointer-events: none;
      }

      img {
        width: 20px;
      }
    }

    .agent {
      min-width: 160px;
      border-radius: 5px;
    }
  
    .search {
      padding: 0.7em;
      background-color: rgba($color: #000000, $alpha: 0.02);
      border-color: rgba($color: #000000, $alpha: 0.13);
      border-radius: 5px;
      min-width: 170px;
    }

    .year {
      min-width: 130px;
      border-radius: 5px;
    }
  }

  .content {
    padding: 2rem 30px 1rem 30px;
  }
}